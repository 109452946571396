import './components/aiSelect.js';
import { contactSubmit } from './contact.js';
import FilterUnits from './services/FilterUnits.js';
import Map from "./services/Map.js";
import Slider from "./services/Slider.js";


if (document.getElementById('contact_form')) {
	contactSubmit();
}

document.addEventListener('DOMContentLoaded', () => {

	new Slider('[data-slider="info-slider"]');
	new FilterUnits('[data-js="facility-rates-node"]', '[data-js="facility-filters-node"]');
	new Map('[data-map="map-contact"]');

	// Mobile menu
	let menuIcon = document.querySelector(".mobile_btn_wrap");
	let mobileMenu = document.querySelector(".mobile-menu");
	let mobileMenuCloseIcon = document.querySelector(".exit-mobile");

	menuIcon.addEventListener("click", function () {
		mobileMenu.classList.add("active");
		mobileMenuCloseIcon.classList.add("active");
	});

	mobileMenuCloseIcon.addEventListener("click", function () {
		mobileMenu.classList.remove("active");
		mobileMenuCloseIcon.classList.remove("active");
	});


	// Toggle function
	window.toggleExpand = function (element) {
		if (!element.style.height || element.style.height === '0px') {
			//element.style.height = Array.prototype.reduce.call(element.childNodes, function (p, c) { return p + (c.offsetHeight || 0); }, 0) + 'px';
			element.style.height = '440px';
		} else {
			element.style.height = '0px';
		}
	};


	//Rates page tabs
	let tab = document.querySelectorAll(".tab li");


	tab.forEach(e => {
		e.addEventListener('click', function () {

			let activeel = document.querySelector(".tab li.active");
			activeel.classList.remove('active');


			let attr = e.getAttribute('data-tab');
			let tabs = document.querySelectorAll(".content");
			tabs.forEach(e => {
				e.style.display = 'none';
			});
			e.classList.add('active');
			document.querySelector(`.content[data-id=${attr}]`).style.display = 'block';
		});
	}
	);


	//rates filter
	// let filtertab = document.querySelectorAll(".filter li");


	// filtertab.forEach(e => {
	// 	e.addEventListener('click', function () {
	// 		let activeel = document.querySelector(".filter li.active");
	// 		if (activeel != null) {
	// 			activeel.classList.remove('active')
	// 		}
	// 		e.classList.add('active')
	// 	})
	// }
	// );



}, true);
